<template>
  <div
    @drop="onDropOnWidgets($event, 1)"
    @contextmenu="this.setContextIdWidget"
    class="shadow-lg"
    :style="{
      borderRadius: '10px',
    }"
    :id="'bargauge' + idx"
  >
    <span class="hidden">{{ darkDark }}</span>
    <div class="hidden">{{ isChangeVerticalBar }}</div>
  </div>
</template>

<script>
import { select, axisLeft, selectAll, scaleLinear } from "d3";
import { isNumber } from "util";
import helperServices from "../../../helper-services";
var data = {
  soundurl: "http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3",
};
import apiService from "../../../api/services";
import { useReplay } from "../../../composable/useReplay.js";
let id = null;
let [svgWidth, svgHeight] = [400, 400];
let graphSize = (svgWidth * 1) / 3;
let fontSize = 22;
let svg;
let space = 20;
let marginLeft = 25;
let marginRight = 50;
let marginTop = 15;
let domainStart = 0;
let domainEnd = 200;

export default {
  name: "vertical-bar-widget",
  sockets: {
    connect: function () {},
    disconnect() {
      this.isConnected = false;
      try {
        let topic = this.topic;
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },
    reconnect() {
      this.$socket.emit("subscribe", {
        logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.widgetId,
        clientId: this.$clientId,
      });
    },
  },
  mounted() {
    let value;
    let valueAt = "end";
    //
    let displayId = this.displayId;
    let displayDtls = this.$store.state.disp.displays[displayId];
    if (displayDtls) {
      this.wellId = displayDtls.wellId;
      this.wellboreId = displayDtls.wellboreId;
      this.max = displayDtls.max;
      this.logId = displayDtls.logId;
      this.status = displayDtls.status;
      this.logType = displayDtls.logType;
      this.logTypeId = displayDtls.logTypeId;
      if (this.fullName) {
        this.displayName = this.fullName;
        this.defaultTitle = this.title;
        this.barValue = "";
        if (displayDtls.status == "UPDATING") {
          this.sockets.unsubscribe(this.$clientId);
          let tempId = this.widgetId;

          this.$socket.emit("unsubscribe", {
            logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
            topic: this.topic,
            id: tempId,
            clientId: this.$clientId,
          });
          setTimeout(this.getRealtimeData, 200);
        }
        // else {
        setTimeout(() => {
          this.getTagValue(displayDtls, this.title);
        }, 300);

        // }
      }
    }

    if (this.alertBlinker) {
      clearInterval(this.alertBlinker);
      this.newAlertColor = this.alertColor;
    }
    if (this.blinkAlert) {
      this.isBlinkAlert = true;
      let i = 0;
      this.alertBlinker = setInterval(() => {
        let color = ["black", this.alertColor];
        i = (i + 1) % color.length;
        this.newAlertColor = color[i];
        if (!this.isBlinkAlert) {
          clearInterval(this.isBlinkAlert);
          this.newAlertColor = this.alertColor;
        }
      }, 1000);
    }

    this.buildBarGauge();
    if (!this.isReplay) {
      this.$store.subscribe(async (mutation, state) => {
        let title = this.title;

        if (mutation.type == "data/setSingleValue") {
          let display = this.$store.state.disp.displays[this.displayId];

          let valueAt = "end";
          let name =
            title + "-" + this.displayId + "-" + display.logId + "-" + valueAt;

          let plName =
            mutation.payload.title +
            "-" +
            mutation.payload.display +
            "-" +
            mutation.payload.logId +
            "-" +
            mutation.payload.valueAt;

          if (name == plName) {
            this.barValue = mutation.payload.value;
            //
            if (this.rangeEndDy > this.rangeStartDy) {
              this.defaultInterval = (this.rangeEndDy - this.rangeStartDy) / 10;
              this.updateBarGauge();
            }
          }
        }
        if (
          mutation.type == "disp/setDisplay" &&
          mutation.payload.display == this.displayId
        ) {
          if (this.socket) this.socket.disconnect();
          this.wellId = mutation.payload.wellId;
          this.wellboreId = mutation.payload.wellboreId;
          this.logId = mutation.payload.logId;
          this.status = mutation.payload.status;
          this.logType = mutation.payload.logType;
          this.logTypeId = mutation.payload.logTypeId;
          this.table = mutation.payload.table;
          this.topic = mutation.payload.topicName;
          this.displayName = this.fullName;
          this.defaultTitle = this.title;
          this.barValue = "";
          if (mutation.payload.status == "UPDATING") {
            this.sockets.unsubscribe(this.$clientId);
            let tempId = this.widgetId;

            this.$socket.emit("unsubscribe", {
              logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
              topic: this.topic,
              id: tempId,
              clientId: this.$clientId,
            });
            setTimeout(this.getRealtimeData, 200);
          }
          // else {
          setTimeout(() => {
            this.getTagValue(mutation.payload, this.title);
          }, 300);

          this.updateBarGauge();
        }
        if (mutation.type == "data/displayChanged") {
          let valueAt = "end";
          let name =
            title + "-" + this.displayId + "-" + this.logId + "-" + valueAt;

          if (name == mutation.payload) {
            this.barValue = this.$store.state.data[name].value;
          }
        }

        if (mutation.type == "disp/setTags") {
          if (mutation.payload.details.display == this.displayId) {
            let unit =
              this.$store.state.disp.displays[this.displayId].units[
                this.$store.state.disp.displays[this.displayId].tags.indexOf(
                  this.title
                )
              ];

            this.$store.dispatch("rect/changeUnit", {
              displayId: this.displayId,
              widgetId: this.widgetId,
              value: unit,
            });
          }
        }
      if(mutation.type == "disp/MINIMIZE_DISP" && this.displayId== mutation.payload.displayId){  
          this.$socket.emit("unsubscribe", {
            logId: this.logId,
            topic: this.topic,
            id: this.widgetId,
            clientId: this.$clientId,
          });
      }
      if(mutation.type == "disp/MAXIMIZE_DISP" && this.displayId== mutation.payload.displayId){
        this.$socket.emit("subscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          fields:this.fields,
          id: this.widgetId,
          clientId: this.$clientId,
          logType: this.logType,
        });
      }
      });
    }
  },
  props: {
    autoFit: {
      type: Boolean,
      default: true,
    },
    isChanged: Boolean,
    blinkAlert: Boolean,
    decimalValue: String,
    borderColor: {
      type: String,
      default: "var(--defaultBorderColorForWidgets)",
    },
    borderWidth: {
      type: Number,
      default: 1,
    },
    openConfig: Function,
    barWidgetConfig: Object,
    title: {
      type: String,
      default: "",
    },
    fullName: {
      type: String,
      default: "",
    },
    titleSize: {
      type: Number,
      default: 32,
    },
    alertStartRange: {
      type: Number,
    },
    alertEndRange: {
      type: Number,
    },
    alertColor: {
      type: String,
    },
    value: {
      type: String,
      default: "160",
    },
    valueSize: {
      type: Number,
      default: 18,
    },

    unit: {
      type: String,
      default: "",
    },
    unitSize: {
      type: [Number, String],
      default: "14",
    },

    width: {
      type: Number,
      default: 120,
    },
    height: {
      type: Number,
      default: 520,
    },

    barWidth: {
      type: Number,
      default: 40,
    },
    barHeight: {
      type: Number,
      default: 380,
    },
    borderDisplay: {
      type: Boolean,
      default: false,
    },
    // barValue: {
    //   type: String,
    //   default: "85",
    // },
    rangeStart: {
      type: Number,
      default: 0,
    },
    rangeEnd: {
      type: Number,
      default: 200,
    },
    interval: {
      type: Number,
      default: 20,
    },
    titleColor: {
      type: String,
      default: null,
    },
    valueColor: {
      type: String,
      default: null,
    },
    unitColor: {
      type: String,
      default: null,
    },
    unitDisplay: {
      type: Boolean,
      default: true,
    },
    titleDisplay: {
      type: Boolean,
      default: true,
    },

    valueDisplay: {
      type: Boolean,
      default: true,
    },
    cardBackground: {
      type: String,
      default: "#3f3f3f",
    },
    verticalcardBG: {
      default: "var(--navBar2Bg)",
    },
    lightThemeDarkColor: {
      default: "var(--textColor)",
    },
    id: "",
    barBreakPoints: {
      type: Array,
      default: [
        // { value: 30, color: "#9999FF" },
        // { value: 60, color: "#9999FF" },
        // { value: 140, color: "#9999FF" },
      ],
    },
    displayId: String,
    widgetId: String,
    isReplay: {
      type: Boolean,
      default: false,
    },
    unit_conversion_factor: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      alertBlinker: null,
      logTypeId: "",
      idx: -1,
      newAlertColor: this.alertColor,
      isBlinkAlert: false,
      isPlayed: false,
      decimals: 2,
      widgetPropertyIndex: -1,
      defaultTitle: "",
      barValue: null,
      rangeStartDy: 0,
      rangeEndDy: this.rangeEnd || 200,
      defaultInterval: this.interval || 20,
      wellId: "",
      wellboreId: "",
      logId: "",
      status: "",
      logType: "",
      table: null,
      socket: null,
      displayName: "",
      titleColorLocal: this.titleColor || "var(--textColor)",
      valueColorLocal: this.valueColor || "var(--textColor)",
      unitColorLocal: this.unitColor || "var(--textColor)",
      barValueOriginal:""
    };
  },
  beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.widgetPropertyIndex = i;
      }
    }
    this.idx = this.widgetId;

    let displayId = this.displayId;
    let displayDtls = this.$store.state.disp.displays[displayId];
    if (displayDtls) {
      this.wellId = displayDtls.wellId;
      this.wellboreId = displayDtls.wellboreId;
      this.max = displayDtls.max;
      this.logId = displayDtls.logId;
      this.status = displayDtls.status;
      this.logType = displayDtls.logType;
      this.logTypeId = displayDtls.logTypeId;
      this.table = displayDtls.table;
      this.topic = displayDtls.topicName;
    }
  },
  created() {},
  beforeUnmount() {
    let topic = this.topic;

    if (this.$socket) {
      this.sockets.unsubscribe(this.$clientId);
      let tempId = this.widgetId;

      this.$socket.emit("unsubscribe", {
        logId: this.logId,
        topic: this.topic,
        id: tempId,
        clientId: this.$clientId,
      });
    }
  },
  methods: {
    formatNumber(number) {
      const suffixes = ["", "k", "M", "B", "T"]; // Add more suffixes as needed
      let suffixIndex = 0;

      while (number >= 1000 && suffixIndex < suffixes.length - 1) {
        number /= 1000;
        suffixIndex++;
      }

      return Number(number.toFixed(1)) + suffixes[suffixIndex];
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      // e.stopPropagation();
    },
    getRealtimeData(title) {
      let topic = this.topic;

      let wells = this.$store.state.data.wells;
      let well = wells[this.wellId];
      if (well) {
        let wellbore = well.wellbores[this.wellboreId];
        let log = wellbore["logs"][this.logType];
        this.logId = log.id;
        let fields = [title || this.title];

        this.fields = fields;
        let topicExists = this.$store.state.live.isSubscribed[topic];

        this.$socket.emit("subscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: topic,
          fields,
          id: this.widgetId,
          clientId: this.$clientId,
          logType: this.logType,
        });
        if (!topicExists) {
          this.$store.dispatch("live/setSubscriptionFlag", {
            topic,
            flag: true,
          });
        }

        this.sockets.subscribe(this.$clientId, (sResult) => {
          let result = sResult[this.widgetId];

          if (result) {
            let value = result[this.defaultTitle] || result[this.title];
            // console.log(
            //   "~~~~~~~~ this. value ",
            //   this.defaultTitle,
            //   this.title,
            //   result
            // );
            if (value && value.length) {
              this.barValue = Number(value[value.length - 1]);
              if(this.unit_conversion_factor && this.unit_conversion_factor!=""){
                this.barValue *= this.unit_conversion_factor;
              }
              
              this.barValueOriginal = Number(value[value.length - 1]);
            }
          }
        });

        // }

        topicExists = this.$store.state.live.isSubscribed[topic];
      }
    },
    onDropOnWidgets(event) {
      //
      event.stopPropagation();
      let onDropDtls = JSON.parse(event.dataTransfer.getData("elProps"));
      this.$store.dispatch("rect/changeTitle", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: onDropDtls.title,
        fullName: onDropDtls.fullName,
      });
      this.$store.dispatch("rect/changeUnit", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: onDropDtls.units,
      });

      // let displayDtls = this.$store.state.disp.displays[this.displayId];
      this.barValue = "";

      this.displayName = onDropDtls.fullName;
      this.defaultTitle = onDropDtls.title;
      if (this.status == "UPDATING") {
        // if (this.socket) this.socket.disconnect();
        this.sockets.unsubscribe(this.$clientId);
        let tempId = this.widgetId;

        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
          topic: this.topic,
          id: tempId,
          clientId: this.$clientId,
        });
        this.getRealtimeData(this.defaultTitle);
      }
      // else {
      //   this.defaultTitle = onDropDtls.title;
      //   this.displayName = onDropDtls.fullName;
      setTimeout(() => {
        this.getTagValue({}, onDropDtls.title);
      }, 300);

      // }
    },
    async getMnemonicType(mnemonic) {
      // let all_mnemonics =
      //   this.$store.state.disp.displays[this.displayId].mnemonics;
      let logDetails = await helperServices.getIndexDetails(this.wellId);
      let all_mnemonics = logDetails.data.logs[this.logType].mnemonics;
      const source_mnemonice = logDetails.data.logs[this.logType].source;
      const tags = logDetails.data.logs[this.logType].tags;
      let mnemonic_type = "UNPREFERRED";
      const mnemonicKey = tags.findIndex(t=>t==mnemonic);
      const actualKey = source_mnemonice[mnemonicKey];
      for (let i in all_mnemonics) {
        if (all_mnemonics[i][actualKey]) {
          mnemonic_type = i;
          break;
        }
      }
      return mnemonic_type;
    },
    async getTagValue(displayDtls, title) {
      if (this.max) {
        let result = "";
        let mnemonic_type = await this.getMnemonicType(title || this.title);
        if (this.logType == "time")
          result = await apiService.WellServices.getSingleValueTimeLog({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            field: title || this.title,
            type: mnemonic_type,
            max: this.max,
            topic: this.topic,
          });
        else
          result = await apiService.WellServices.getSingleValueLog({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            field: title || this.title,
            type: mnemonic_type,
            max: this.max,
            topic: this.topic,
          });
        if (result != null) {
          this.barValue = result.data[0][this.title];
          this.barValueOriginal =Number(result.data[0][this.title]);
        }

        if (this.rangeEndDy > this.rangeStartDy) {
          this.defaultInterval = (this.rangeEndDy - this.rangeStartDy) / 10;
        }
      }
      this.updateBarGauge();
      return this.barValue;
    },
    clearSvgHandler() {
      return new Promise((res, rej) => {
        let gauge = select("#bargauge" + this.idx);
        gauge.selectAll("*").remove();
        res();
      });
    },
    playSound() {
      this.isPlayed = !this.isPlayed;
      var audio = new Audio(data.soundurl);
      audio.play();
      setTimeout(() => {
        this.isPlayed = !this.isPlayed;
      }, 12000);
    },
    buildBarGauge() {
      // let bar = document.getElementById("bargauge").appendChild(document.createElement("div").setAttribute("id", "bargauge" + this.idx));

      svg = null;
      let titleSize = this.titleSize || 32;
      let valueSize = 18;
      let unitSize = 14;
      domainStart = this.rangeStartDy || Number(this.rangeStart);
      // ? Number(this.rangeStart)
      // : this.rangeStartDy;
      domainEnd = this.rangeEndDy || Number(this.rangeEnd);
      // ? this.rangeEnd
      // : this.rangeEndDy;

      let width = this.width;
      let height = this.height;
      let barWidth = width - marginRight;
      let barHeight = height - this.height * 0.27;

      let barValue =
        this.barValue < this.rangeStartDy ? this.rangeStartDy : this.barValue;
      let margingForLabels =
        (this.rangeEnd.toString().length * this.height * this.width) / 1000000;

      svg = select("#bargauge" + this.idx)
        .append("svg")
        .attr("width", width)
        .attr("height", height - height * 0.04);
      let verticalBg =
        this.darkDark !== "white" ? this.verticalcardBG : "var(--widgetBg)"; //this.cardBackground

      let mainRect = svg
        .append("rect")
        .attr("width", width)
        .attr("height", height - height * 0.04)
        // .attr("rx", 15)
        // .attr("ry", 15)

        // .style("stroke", this.borderDisplay ? "" : "")
        .style("stroke", this.borderDisplay ? this.borderColor || "white" : "")
        .style("stroke-width", this.borderDisplay ? this.borderWidth : "")
        .attr("fill", verticalBg)
        .style("background", verticalBg)
        .style("transition", "var(--transition)");
      // Add the path using this helper function
      svg
        .append("rect")
        .attr("id", "bar" + this.idx)
        .attr("x", marginLeft + 10 + margingForLabels)
        .attr("y", marginTop)
        // .attr("rx", 4)
        // .attr("ry", 4)
        .attr("width", barWidth)
        .attr("height", barHeight)
        .attr("stroke", verticalBg)
        .attr("fill", "var(--verticalBarScaleRectBg)");

      // Creating Small Ticks
      let convertedStartDomain =
        domainStart < 0 ? domainStart * -1 : domainStart;

      // Create the scale
      let tickLables = [];
      let minorTickCount = 0;
      let minorTickLables = [];
      for (let i = domainStart; i <= domainEnd; i = i + this.defaultInterval) {
        tickLables.push(i);
        minorTickLables.push(i);
        if (i + this.defaultInterval / 2 <= domainEnd) {
          minorTickLables.push(i + this.defaultInterval / 2);
        }
      }

      var ySmall = scaleLinear()
        .domain([domainStart, domainEnd]) // This is what is written on the Axis: from 0 to 100
        .range([domainStart + barHeight - 2, domainStart + 1.5]); // Note it is reversed

      var axisSmall = svg
        .append("g")
        .attr("id", "leftAxisSmall" + this.idx)
        // .attr("stroke", "#ffff")
        // .attr("fill", "#ffff")
        .attr(
          "transform",
          "translate(" +
            (parseInt(marginLeft) + 5 + margingForLabels) +
            "," +
            (parseInt(marginTop) - domainStart) +
            ")"
        ) // This controls the vertical position of the Axis
        .call(
          axisLeft(ySmall)
            // .tickFormat((d, i) => {
            //
            //   return d % this.defaultInterval ? d : "";
            // })
            // .ticks(minorTickCount)
            .tickValues(minorTickLables)
            .tickSize(-5)
        );
      axisSmall
        .selectAll("line")
        .style("stroke", verticalBg)
        .attr("stroke-width", "0.5px");

      axisSmall.selectAll("path").style("opacity", "0");

      axisSmall.selectAll("text").style("opacity", "0").style("fill", "white");

      var y = scaleLinear()
        .domain([domainStart, domainEnd]) // This is what is written on the Axis: from 0 to 100
        .range([domainStart + barHeight - 2, domainStart + 1.5]); // Note it is reversed

      var axis = svg
        .append("g")
        .attr("id", "leftAxis" + this.idx)
        // .attr("stroke", "#ffff")
        // .attr("fill", "#ffff")
        .attr(
          "transform",
          "translate(" +
            (marginLeft + margingForLabels) +
            "," +
            (parseInt(marginTop) - domainStart) +
            ")"
        ) // This controls the vertical position of the Axis
        .call(
          axisLeft(y)
            .tickValues(tickLables)
            .tickSize(-10)
            .tickFormat((t) => this.formatNumber(t))
        );

      let verticalCol =
        this.darkDark !== "white" ? "white" : this.lightThemeDarkColor;

      axis.selectAll("line").style("stroke", verticalCol);

      axis.selectAll("path").style("opacity", "0");

      axis
        .selectAll("text")
        .style("fill", verticalCol)
        .attr("font-size", this.height * 0.03);

      if (this.barBreakPoints && this.barBreakPoints.length) {
        let barY;
        for (let i = 0; i <= this.barBreakPoints.length; i++) {
          // if (i == 3) {
          let start = 0;
          let end = 0;
          let fillColor = "#9999FF";

          if (i == 0) {
            end = this.barBreakPoints[i].value;
            // fillColor = this.barBreakPoints[i].color;
          } else if (i == this.barBreakPoints.length) {
            start = this.barBreakPoints[i - 1].value;
            end = barValue;
            fillColor = this.barBreakPoints[i - 1].color
              ? this.barBreakPoints[i - 1].color
              : "#9999FF";
          } else {
            start = this.barBreakPoints[i - 1].value;
            end = this.barBreakPoints[i].value;
            fillColor = this.barBreakPoints[i - 1].color;
          }
          //
          if (i == 0) {
            barY = barHeight - (y(start) - y(end)) + marginTop;
          } else {
            barY = barY - (y(start) - y(end));
          }
          svg
            .append("rect")
            .attr("id", "bar" + i + "Value" + this.idx)
            .attr("x", marginLeft + 10.5 + margingForLabels)
            .attr("y", barY)
            .attr("width", barWidth - 0.5)
            .attr("height", y(start) - y(end))
            // .attr("stroke", "black")
            .attr("fill", fillColor);
          // }
        }
      } else {
        let endDomainValue =
          Number(domainEnd) > 0 && Number(domainEnd) < 10
            ? Number(domainEnd)
            : Number(domainEnd) > 10 && Number(domainEnd) < 100
            ? Number(domainEnd) + 0.5
            : Number(domainEnd) + 2;

        let barValue =
          Number(this.barValue) > Number(domainEnd)
            ? endDomainValue
            : Number(this.barValue) < Number(domainStart)
            ? Number(domainStart)
            : Number(this.barValue);

        svg
          .append("rect")
          .attr("id", "bar0Value" + this.idx)
          .attr("x", marginLeft + 11 + margingForLabels)
          .attr("y", barHeight - (y(domainStart) - y(barValue)) + marginTop)
          .attr("width", barWidth - 2)
          .attr("height", y(domainStart) - y(barValue))
          // .attr("stroke", "black")
          .attr("fill", "#9999FF");
      }

      // this.autoFit
      // ? this.widgetWidth < this.widgetHeight
      //   ? this.widgetWidth * 1.6 + '%'
      //   : this.widgetHeight * 1.6 + '%'
      // : this.titleSize * 3 + '%'

      svg
        .append("text")
        .attr("id", "title" + this.idx)
        .attr("x", marginLeft + this.width * 0.19)
        .attr("y", barHeight + this.height * 0.03 + space)
        .text(this.displayName.toUpperCase() || "")
        .style(
          "font-size",
          this.autoFit ? this.height * 0.03 + "px" : this.titleSize * 3 + "%"
        )
        // .style("font-family", "'Lato', sans-serif")
        .style("font-weight", "100")
        .attr("text-anchor", "middle")
        .style("fill", "var(--textColor)")
        .attr("class", "truncate");

      svg
        .append("text")
        .attr("x", marginLeft + this.width * 0.18)
        .attr("id", "value" + this.idx)
        .attr("y", barHeight + this.height * 0.03 + this.height * 0.05 + space)
        .text(
          isNaN(
            Number(this.barValue).toFixed(
              this.decimalValue || this.decimalValue == 0
                ? this.decimalValue
                : 2
            )
          )
            ? (0.0).toFixed(
                this.decimalValue || this.decimalValue == 0
                  ? this.decimalValue
                  : 2
              )
            : Number(this.barValue).toFixed(
                this.decimalValue || this.decimalValue == 0
                  ? this.decimalValue
                  : 2
              )
        )
        .style("font-size", this.height * 0.035 + "px")
        // .style("font-family", "'Lato', sans-serif")
        .attr("text-anchor", "middle")
        .style("fill", "var(--textColor)");

      svg
        .append("text")
        .attr("x", marginLeft + this.width * 0.18)
        .attr("id", "unit" + this.idx)
        .attr(
          "y",
          barHeight +
            this.height * 0.03 +
            this.height * 0.05 +
            this.height * 0.05 +
            space
        )
        .text(this.unit)
        .style("font-size", this.height * 0.03 + "px")
        // .style("font-family", "'Lato', sans-serif")
        .attr("text-anchor", "middle")
        .style("fill", "var(--textColor)");
    },
    updateBarGauge() {
      // alert()
      // console.log("🚀 ➽ file: VerticalBarWidget.vue:758  ➽ buildBarGauge  ➽ barValue ⏩", this.barValue)
      let updateTitleWidth = select("svg #title" + this.idx).node();
      // .getBBox();
      //
      let margingForLabelsUpdate =
        (this.rangeEnd.toString().length * this.height * this.width) / 1000000;
      let widthToChange =
        marginLeft + this.width * 0.18 + margingForLabelsUpdate;

      if (this.width > widthToChange) {
        widthToChange = this.width;
      }

      let barHeightUpdated = this.height * 0.72;
      let verticalBg =
        this.darkDark !== "white" ? this.verticalcardBG : "var(--widgetBg)";

      let update = select("#bargauge" + this.idx + " svg")
        .attr("width", Number(widthToChange) + 1)
        .attr("height", Number(this.height - this.height * 0.04) + 1);

      let updateRect = select("#bargauge" + this.idx + " svg rect")
        .attr("width", Number(widthToChange))
        .attr("height", Number(this.height - this.height * 0.04))
        .style("stroke", this.borderDisplay ? this.borderColor || "white" : "")
        .style("stroke-width", this.borderDisplay ? this.borderWidth : "")
        .attr("fill", verticalBg);

      domainStart = this.rangeStartDy || Number(this.rangeStart);
      domainEnd = this.rangeEndDy || Number(this.rangeEnd);

      let endDomainValue =
        Number(domainEnd) > 0 && Number(domainEnd) < 10
          ? Number(domainEnd)
          : Number(domainEnd) > 10 && Number(domainEnd) < 100
          ? Number(domainEnd) + 0.5
          : Number(domainEnd) + 2;

      let barValue =
        Number(this.barValue) > Number(domainEnd)
          ? endDomainValue
          : Number(this.barValue) < Number(domainStart)
          ? Number(domainStart)
          : Number(this.barValue);
      let oldAxisSmall = selectAll("svg #leftAxisSmall" + this.idx).remove();
      // Creating Small Ticks
      //

      let tickLables = [];
      let minorTickCount = 0;
      let minorTickLables = [];
      minorTickCount = parseInt(
        ((domainEnd - domainStart) / this.defaultInterval) * 2
      );

      for (let i = domainStart; i <= domainEnd; i = i + this.defaultInterval) {
        tickLables.push(i);
        minorTickLables.push(i);
        if (i + this.defaultInterval / 2 <= domainEnd) {
          minorTickLables.push(i + this.defaultInterval / 2);
        }
      }

      //
      var ySmall = scaleLinear()
        .domain([domainStart, domainEnd]) // This is what is written on the Axis: from 0 to 100
        .range([domainStart + barHeightUpdated - 2, domainStart + 1.5]); // Note it is reversed

      var axisSmall = update
        .append("g")
        .attr("id", "leftAxisSmall" + this.idx)
        // .attr("stroke", "#ffff")
        // .attr("fill", "#ffff")
        .attr(
          "transform",
          "translate(" +
            (parseInt(marginLeft) + 5 + margingForLabelsUpdate) +
            "," +
            (parseInt(marginTop) - domainStart) +
            ")"
        ) // This controls the vertical position of the Axis
        .call(
          axisLeft(ySmall)
            // .tickFormat((d, i) => {
            //
            //   return d % this.defaultInterval ? d : "";
            // })
            // .ticks(minorTickCount)
            .tickValues(minorTickLables)
            .tickSize(-5)
        );
      let verticalCol =
        this.darkDark !== "white" ? "white" : this.lightThemeDarkColor;
      axisSmall.selectAll("line").style("stroke", verticalCol);

      axisSmall.selectAll("path").style("opacity", "0");

      axisSmall
        .selectAll("text")
        .style("opacity", "0")
        .style("fill", verticalCol);

      let oldAxis = selectAll("svg #leftAxis" + this.idx).remove();
      var y = scaleLinear()
        .domain([
          domainStart,
          Math.ceil(
            ((domainEnd - domainStart) / this.defaultInterval) *
              this.defaultInterval
          ) + domainStart,
          // domainEnd
        ]) // This is what is written on the Axis: from 0 to 100
        .range([domainStart + barHeightUpdated - 2, domainStart + 1.5]); // Note it is reversed

      var axis = update
        .append("g")
        .attr("id", "leftAxis" + this.idx)
        // .attr("stroke", "#ffff")
        // .attr("fill", "#ffff")
        .attr(
          "transform",
          "translate(" +
            (marginLeft + margingForLabelsUpdate) +
            "," +
            (parseInt(marginTop) - domainStart) +
            ")"
        ) // This controls the vertical position of the Axis
        .call(
          axisLeft(y)
            //.ticks((domainEnd - domainStart) / this.defaultInterval)
            .tickValues(tickLables)
            .tickSize(-10)
            .tickFormat((t) => this.formatNumber(t))
        );
      axis.selectAll("line").style("stroke", verticalCol);

      // axis.selectAll("path").style("stroke", "black");
      axis.selectAll("path").style("opacity", "0");

      axis
        .selectAll("text")
        .style("fill", verticalCol)
        .attr("padding", "10px")
        .attr("font-size", "9px")
        .attr("letter-spacing", "0.4px");

      let updateBar = select("svg #bar" + this.idx)
        .attr("x", marginLeft + 10 + margingForLabelsUpdate)
        .attr("width", this.width - marginRight)
        .attr("height", barHeightUpdated);

      // .attr("fill", "#34D399");
      let fillColor = "#9999FF";
      let barHight = this.barValue;
      let isAboveDanger = false;
      //
      //
      //

      if (
        // parseInt(this.alertStartRange) > 0 && parseInt(this.alertEndRange) > 0
        !isNaN(this.alertStartRange) &&
        !isNaN(this.alertEndRange)
      ) {
        // if (this.playAlarmSound && !this.isPlayed) {
        //   this.playSound();
        // }
        // fillColor = "";
        // var i = 0;
        // setInterval(() => {
        //   var color = ["#f32013"];
        //
        //   fillColor = color[i];
        //   i = (i + 1) % color.length;
        // }, 1000);
        //
        //
        //
        //

        if (
          this.isBlinkAlert &&
          (parseInt(this.barValue) < parseInt(this.alertStartRange) ||
            parseInt(this.barValue) > parseInt(this.alertEndRange))
        ) {
          fillColor = this.newAlertColor || "#F32013";
          isAboveDanger = true;
          barHight = this.alertStartRange;
        } else {
          fillColor = "#9999FF";
        }
      }

      //

      let updateBarValue;
      console.log(
        " y of bar : ",
        barHeightUpdated + marginTop - (y(domainStart) - y(barValue)),
        y(domainStart) - y(barValue)
      );
      if (this.barBreakPoints && this.barBreakPoints.length) {
        let breakPointsArray = this.barBreakPoints;
        function compare(a, b) {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        }

        breakPointsArray.sort(compare);
        let start = 0;
        let end = 0;
        let fillColor = "#9999FF";
        let barY;
        for (let i = 0; i <= breakPointsArray.length; i++) {
          if (i == 0) {
            end =
              Number(breakPointsArray[i].value) > Number(domainEnd)
                ? Number(domainEnd)
                : Number(breakPointsArray[i].value);
            // fillColor = breakPointsArray[i].color;
          } else if (i == breakPointsArray.length) {
            //
            start = breakPointsArray[i - 1].value;

            let endDomainValue =
              Number(domainEnd) > 0 && Number(domainEnd) < 10
                ? Number(domainEnd)
                : Number(domainEnd) > 10 && Number(domainEnd) < 100
                ? Number(domainEnd) + 0.5
                : Number(domainEnd) + 2;

            end =
              Number(this.barValue) > Number(domainEnd)
                ? endDomainValue
                : Number(this.barValue);
            fillColor = this.barBreakPoints[i - 1].color
              ? this.barBreakPoints[i - 1].color
              : "#9999FF";
          } else {
            start = breakPointsArray[i - 1].value;
            end =
              Number(breakPointsArray[i].value) > Number(domainEnd)
                ? Number(domainEnd)
                : Number(breakPointsArray[i].value);
            fillColor = breakPointsArray[i - 1].color;
          }
          //
          let barSection = update.select("#bar" + i + "Value" + this.idx);

          //

          // =
          //   barHeightUpdated -
          //   (y(start) - y(end)) * (i + 1) +
          //   marginTop;
          if (i == 0) {
            barY = barHeightUpdated - (y(start) - y(end)) + marginTop;
          } else {
            barY = barY - (y(start) - y(end));
          }

          if (barSection && barSection["_groups"][0][0]) {
            barSection
              .attr("x", marginLeft + 10.5 + margingForLabelsUpdate)
              .attr("y", barY)
              .attr("width", this.width - marginRight - 0.5)
              .attr("height", y(start) - y(end))
              // .attr("stroke", "black")
              .attr("fill", fillColor);
          } else {
            update
              .append("rect")
              .attr("id", "bar" + i + "Value" + this.idx)
              .attr("x", marginLeft + 10.5 + margingForLabelsUpdate)
              .attr("y", barY)
              .attr("width", this.width - marginRight - 0.5)
              .attr("height", y(start) - y(end))
              // .attr("stroke", "black")
              .attr("fill", fillColor);
          }
        }
      } else {
        updateBarValue = select("svg #bar0Value" + this.idx)
          .attr("x", marginLeft + 11 + margingForLabelsUpdate)
          .attr(
            "y",
            barHeightUpdated + marginTop - (y(domainStart) - y(barValue))
          )
          .attr("width", this.width - marginRight - 2)
          .attr("height", y(domainStart) - y(barValue))
          .attr("fill", fillColor);
      }

      let updateTitle = select("svg #title" + this.idx)
        .attr("x", this.width * 0.5)
        .attr(
          "y",
          parseInt(barHeightUpdated) +
            this.height * 0.03 +
            // parseInt(this.titleSize ? this.titleSize : 0) +
            space
        )
        .text(this.displayName.toUpperCase())
        .style("text-anchor", "middle")
        .style("fill", this.titleColorLocal)
        .style(
          "font-size",
          this.autoFit ? this.height * 0.03 + "px" : this.titleSize * 3 + "%"
        )
        // .style("font-family", "'Lato', sans-serif")
        .attr("class", "truncate");

      if (!this.titleDisplay) {
        updateTitle.style("display", "none");
      } else {
        updateTitle.style("display", "block");
      }
      let updateValue = select("svg #value" + this.idx)
        .attr("x", this.width * 0.5)

        .attr(
          "y",
          parseInt(barHeightUpdated) +
            this.height * 0.03 +
            this.height * 0.05 +
            // parseInt(this.titleSize ? this.titleSize : 0) +
            // parseInt(this.valueSize ? this.valueSize : 0) +
            space
        )
        .text(
          isNaN(
            Number(this.barValue).toFixed(
              this.decimalValue || this.decimalValue == 0
                ? this.decimalValue
                : 2
            )
          )
            ? (0.0).toFixed(
                this.decimalValue || this.decimalValue == 0
                  ? this.decimalValue
                  : 2
              )
            : Number(this.barValue).toFixed(
                this.decimalValue || this.decimalValue == 0
                  ? this.decimalValue
                  : 2
              )
        )
        .style("fill", this.valueColorLocal)
        .style(
          "font-size",
          this.autoFit ? this.height * 0.035 + "px" : this.valueSize * 3 + "%"
        );
      // .style("font-family", "'Lato', sans-serif");

      if (!this.valueDisplay) {
        updateValue.style("display", "none");
      } else {
        updateValue.style("display", "block");
      }

      let updateUnit = select("svg #unit" + this.idx)
        .attr("x", this.width * 0.5)

        .attr(
          "y",
          parseInt(barHeightUpdated) +
            this.height * 0.03 +
            this.height * 0.05 +
            this.height * 0.05 +
            // parseInt(this.titleSize ? this.titleSize : 0) +
            // parseInt(this.valueSize ? this.valueSize : 0) +
            // parseInt(this.unitSize ? this.unitSize : 0) +
            space
        )
        .text(this.unit)
        .style("fill", this.unitColorLocal)
        .style(
          "font-size",
          this.autoFit ? this.height * 0.03 + "px" : this.unitSize * 3 + "%"
        )
        // .style("font-family", "'Lato', sans-serif")
        .attr("text-anchor", "middle");

      if (!this.unitDisplay) {
        updateUnit.style("display", "none");
      } else {
        updateUnit.style("display", "block");
      }
    },
    dark() {
      this.$emit("dark");
    },

    light() {
      this.$emit("light");
    },
  },
  computed: {
    watchOnAllProperties() {
      const {
        width,
        height,
        borderColor,
        borderWidth,
        titleDisplay,
        unitDisplay,
        valueDisplay,
        title,
        barValue,
        unit,
        unitColor,
        valueColor,
        borderDisplay,
        // rangeStart,
        // rangeEnd,
        // interval,
        titleColor,
      } = this;
      this.updateBarGauge();
      return {
        width,
        height,
        borderColor,
        borderWidth,
        titleDisplay,
        unitDisplay,
        valueDisplay,
        title,
        barValue,
        unit,
        titleColor,
        unitColor,
        valueColor,
        borderDisplay,
        // rangeStart,
        // rangeEnd,
        // interval,
      };
    },
    isChangeVerticalBar() {
      let updateTitleWidth = select("svg #title" + this.idx).node();
      if (updateTitleWidth) {
        this.updateBarGauge();
      }

      return this.isChangeVerticalBar;
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  // beforeMount() {
  //   let rects = this.$store.state.rect.rects;
  //   if (typeof rects != "undefined" && rects.length > 0) {
  //     for (let i = 0; i < rects.length; i++) {
  //       if (
  //         rects[i].widgetId == this.widgetId &&
  //         rects[i].displayId == this.displayId
  //       )
  //         this.idx = i;
  //     }
  //   }
  // },
  watch: {
    watchOnAllProperties: {
      handler: async function (val) {
        // console.log("border color checking ::::::",this.borderColor,this.borderWidth,this.borderDisplay)
        //
        let displayDtls = this.$store.state.disp.displays[this.displayId];
        if (displayDtls) {
          this.max = displayDtls.max;
          if (this.defaultTitle != val.title) {
            this.barValue = "";
            this.defaultTitle = val.title;
            if (displayDtls.status == "UPDATING") {
              // if (this.socket) this.socket.disconnect();
              this.sockets.unsubscribe(this.$clientId);
              let tempId = this.widgetId;

              this.$socket.emit("unsubscribe", {
                logId: `${this.wellId}~**~=~**~${this.wellboreId}~**~=~**~${this.logType}`,
                topic: this.topic,
                id: tempId,
                clientId: this.$clientId,
              });
              this.getRealtimeData();
            }
            // else {
            //   this.defaultTitle = val.title;
            setTimeout(() => {
              this.getTagValue({}, val.title);
            }, 300);

            // }
            // let unit =
            //   this.$store.state.disp.displays[this.displayId].units[
            //     this.$store.state.disp.displays[this.displayId].tags.indexOf(
            //       this.defaultTitle.toLowerCase()
            //     )
            //   ];
            // this.$store.dispatch("rect/changeUnit", {
            //   displayId: this.displayId,
            //   widgetId: this.widgetId,
            //   value: unit,
            // });
          }
        }
        // if (val.rangeStart + val.interval <= val.rangeEnd) {
        // this.updateBarGauge();
        // }
      },
    },
    barBreakPoints: function (newVal) {},
    isChanged: function (newVal) {
      this.updateBarGauge();
    },
    rangeStart: async function (newVal) {
      if (newVal < this.rangeEndDy) {
        this.defaultInterval = (this.rangeEndDy - newVal) / 10;
        this.rangeStartDy = newVal;
        await this.clearSvgHandler();
        this.buildBarGauge();
      }
    },
    rangeEnd: async function (newVal) {
      if (this.rangeStartDy < newVal) {
        this.defaultInterval = (newVal - this.rangeStartDy) / 10;
        this.rangeEndDy = newVal;
        await this.clearSvgHandler();
        this.buildBarGauge();
      }
    },
    // interval: async function (newVal) {
    //   if (newVal > 9 && newVal < this.rangeEndDy) {
    //     this.defaultInterval = newVal;
    //     this.updateBarGauge();
    //   }
    // },
    blinkAlert: function (newVal) {
      console.log(
        "in blink alert ",
        isNumber(this.alertStartRange),
        isNumber(this.alertEndRange)
      );
      if (
        isNumber(Number(this.alertStartRange)) &&
        isNumber(Number(this.alertEndRange))
      ) {
        this.isBlinkAlert = newVal;
        if (this.alertBlinker) {
          clearInterval(this.alertBlinker);
        }
        let i = 0;
        if (newVal) {
          this.alertBlinker = setInterval(() => {
            let color = ["black", this.alertColor];
            i = (i + 1) % color.length;
            this.newAlertColor = color[i];
            this.updateBarGauge();
            if (!this.isBlinkAlert) {
              clearInterval(this.alertBlinker);
              this.newAlertColor = this.alertColor;
            }
          }, 1000);
        } else {
          this.newAlertColor = this.alertColor;
          this.updateBarGauge();
        }
      }

      // this.updateBarGauge();
    },
    alertColor: async function (newVal, oldVal) {
      this.newAlertColor = newVal;
      if (this.alertBlinker) {
        clearInterval(this.alertBlinker);
        this.newAlertColor = newVal;
      }

      let i = 0;

      if (this.isBlinkAlert) {
        this.alertBlinker = setInterval(() => {
          let color = ["black", newVal];
          i = (i + 1) % color.length;
          this.newAlertColor = color[i];
          this.updateBarGauge();
          if (!this.isBlinkAlert) {
            clearInterval(this.alertBlinker);
            this.newAlertColor = this.alertColor;
          }
        }, 1000);
      }
      this.updateBarGauge();
    },
    // blinkAlert: function (newVal) {
    //   this.isBlinkAlert = newVal;
    //   if (this.isBlinkAlert == false) {
    //     for (var i = 1; i < id; i++) {
    //       window.clearInterval(i);
    //     }
    //     this.updateBarGauge();
    //   }
    // },
    // alertColor: async function (newVal, oldVal) {
    //   let i = 0;
    //   if (this.isBlinkAlert) {
    //     id = setInterval(() => {
    //       let color = ["black", newVal];
    //       i = (i + 1) % color.length;
    //       this.newAlertColor = color[i];
    //       this.updateBarGauge();

    //       if (!this.isBlinkAlert) {
    //         clearInterval(id);
    //       }
    //     }, 1000);
    //   } else {
    //     this.newAlertColor = newVal;
    //     this.updateBarGauge();
    //   }
    // },

    autoFit: async function (newVal, oldVal) {
      this.updateBarGauge();
    },
    titleSize: async function (newVal, oldVal) {
      this.updateBarGauge();
    },
    valueSize: async function (newVal, oldVal) {
      this.updateBarGauge();
    },

    unitSize: async function (newVal, oldVal) {
      this.updateBarGauge();
    },

    decimalValue(newValue) {
      this.decimals = newValue;
      this.updateBarGauge();
    },

    alertStartRange(newValue, value2) {
      // this.alertStartRange = newValue;
      this.updateBarGauge();
    },
    alertEndRange(newValue, value2) {
      // this.alertEndRange = newValue;
      this.updateBarGauge();

      // if (
      //   this.alertStartRange <= this.barValue &&
      //   this.barValue < this.alertEndRange
      // ) {
      //   this.updateBarGauge();
      // }
    },
    fullName(newVal) {
      this.displayName = newVal;
      this.updateBarGauge();
    },
    unit(newValue, value2) {
      this.updateBarGauge();
    },
    valueColor(newValue, value2) {
      this.valueColorLocal = newValue;
      this.updateBarGauge();
    },
    titleColor(newValue, value2) {
      this.titleColorLocal = newValue;
      this.updateBarGauge();
    },
    unitColor(newValue, value2) {
      this.unitColorLocal = newValue;
      this.updateBarGauge();
    },
    replayDetails(result) {
      if (result) {
        let value = result[this.title];
        if (value && value.length) {
          this.barValue = Number(value[value.length - 1]);
          if (this.rangeEndDy > this.rangeStartDy) {
            this.defaultInterval = (this.rangeEndDy - this.rangeStartDy) / 10;
            this.updateBarGauge();
          }
        }
      }
    },
    unit_conversion_factor(val){
      if(val!=''){
        let tempVal = +this.barValue;
        if(val==1){
          tempVal= this.barValueOriginal;
        }
        this.barValue = tempVal * val;
        this.updateBarGauge();
      }
    }
  },
  setup() {
    const { replayDetails } = useReplay();
    return {
      replayDetails,
    };
  },
};
</script>

<style scoped>
.column-wrapper {
  height: 200px;
  width: 45px;
  background: #cfd8dc;
  transform: rotate(180deg);
  margin: 0 auto;
}

.column {
  width: 45px;
}

.outer-wrapper {
  display: inline-block;
  margin: 5px 15px;
  padding: 25px 15px;
  min-width: 170px;
}

.scale {
  width: 30px;
  font-size: 0.5em;
  margin-left: 10px;
}

#tooltip {
  /* "Arial"; */
  position: absolute;
  padding: 5px;
  /* font-weight: bold; */
  font-size: 12px;
  line-height: 20px;
  border-radius: 3px;
  color: white;
  border: 1px dotted black;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.75);
}

#tt_msg {
  color: yellowgreen;
}

.hidden {
  display: none;
}

.bargauge {
  border: solid;
  border-width: 6px;
  border-color: grey;
}
</style>
